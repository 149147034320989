module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9DJ1G30ETV"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GGS IT Consulting","short_name":"GGS","start_url":"/","background_color":"#592be5","theme_color":"#a2466c","icon":"./static/favicon.svg","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f76b8015205c76ca1d467c2b23da1850"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","languages":["en","pl"],"defaultLanguage":"en","redirect":false,"redirectDefaultLanguageToRoot":false,"ignoredPaths":["**"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2954670808132273"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
